<template>
  <b-row>
    <b-col cols="12" class="d-none d-xl-block">
      <b-row align-v="center" class="mt-5 mb-5 mx-xl-4">
        <b-col cols="auto">
          <h1
            class="mb-0 text-primary"
            style="font-size: 22px; font-weight: 500"
          >
            Messages
          </h1>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <chat
        :conversationlist="conversationList"
        @fetch-conversations="fetchConversationList"
      />
    </b-col>
  </b-row>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

import chat from "@/components/partials/Chat";

import gql from "graphql-tag";

export default {
  components: {
    chat,
  },
  data() {
    return {
      activeItem: "All",
      userid: "",
      conversations: [],
    };
  },
  computed: {
    ...mapGetters({
      conversationList: "message/FETCH_CONVERSATION",
    }),
  },
  watch: {
    "$route.query.patientid": {
      handler(val) {
        if (val) {
          this.$store.dispatch("message/getSingleConversationList", {
            patientid: this.$route.query.patientid,
          });
        } else {
          this.fetchConversationList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchConversationList() {
      this.$store.dispatch("message/getConversationList");
    },
  },
  mounted() {
    window.analytics.page();
    this.userid = localStorage.getItem("userid");
  },
};
</script>
