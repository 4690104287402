<template>
  <div>
    <div
      class="
        tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-px-4
        d-xl-none
      "
    >
      <b-toast
        solid
        toaster="b-toaster-top-center"
        id="cannedReplyToast"
        variant="success"
        title="Reply added!"
        no-hover-pause
        >Your reply has been succesfully added!</b-toast
      >
      <div
        class="tw-flex-initial tw-flex tw-items-center tw-space-x-2"
        v-if="conversationpatient.id != ''"
      >
        <template v-if="showInformation == true">
          <img
            @click="showInformation = false"
            width="25px"
            src="@/assets/images/back_arrow.svg"
          />
        </template>
        <template v-else>
          <img
            width="25px"
            @click="conversationpatient.id = ''"
            src="@/assets/images/back_arrow.svg"
          />
        </template>
        <span v-if="isAdminView">Admin for {{ patientName }}</span>
        <div v-else class="tw-flex tw-items-center tw-space-x-2">
          <b-img
            :src="patientImg"
            rounded="circle"
            width="25px"
            height="25px"
          ></b-img>
          <span>{{ patientName }}</span>
        </div>
      </div>
      <template v-if="conversationpatient.id != ''">
        <div class="tw-flex-initial">
          <b-button
            v-if="isMarkMessagesEnabled && !isAdminView"
            pill
            variant="link"
            class="text-danger"
            size="sm"
            @click="onCancelMarkMessages"
          >
            <b-icon font-scale="1" icon="x-circle-fill"></b-icon>
          </b-button>
          <b-dropdown
            v-if="!isAdminView"
            id="forward-dropdown"
            right
            split
            variant="primary"
            :split-variant="
              forwardRequestCounter === 1 ? 'primary' : 'outline-primary'
            "
            text="Forward"
            size="sm"
            @click="onForwardRequest"
          >
            <b-dropdown-item @click="$bvModal.show('viewForwardMessage')">
              View Forward Requests
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            menu-class="custom-dropdown-menu"
            no-caret
            right
            size="sm"
          >
            <template #button-content>
              <b-icon font-scale="1" icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              v-if="!isAdminView"
              class="tw-uppercase"
              link-class="tw-text-primary"
              @click="isAdminView = true"
            >
              View Admin Messages
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              class="tw-uppercase"
              link-class="tw-text-primary"
              @click="isAdminView = false"
            >
              View Patient Messages
            </b-dropdown-item>
            <b-dropdown-item class="tw-uppercase" link-class="tw-text-primary">
              Intake History
            </b-dropdown-item>
          </b-dropdown>

          <b-icon
            icon="file-earmark-person"
            variant="primary"
            @click="showInformation = true"
          ></b-icon>
        </div>
      </template>
      <template v-else>
        <h3 class="text-primary text-center mb-0 tw-flex-1">Messages</h3>
      </template>
    </div>
    <ul
      class="
        mobile-chat
        tw-relative tw-flex tw-flex-col tw-w-full tw-list-none tw-p-0 tw-mb-0
        xl:tw-border
        xl:tw-border-solid
        xl:tw-border-[#E0E0E0]
        xl:tw-border-opacity-[0.5]
        d-xl-none
      "
    >
      <li
        ref="mobileConversationListRef"
        class="
          tw-chat-list tw-relative tw-h-[calc(100vh-140px)]
          xl:tw-h-[calc(100vh-320px)]
          tw-overflow-auto
        "
      >
        <div
          class="
            tw-chat-list-header
            tw-flex
            tw-items-center
            tw-border-0
            tw-border-b
            tw-border-t
            tw-border-[#E0E0E0]
            tw-border-opacity-[0.5]
            tw-border-solid
            tw-sticky
            tw-top-0
            tw-z-[10]
            xl:tw-border-t-0
          "
        >
          <div class="input-group input-group-flush">
            <div class="input-group-prepend tw-px-6">
              <span class="input-group-text">
                <i class="fe fe-search text-primary"></i>
              </span>
            </div>
            <input
              v-model="filter"
              class="form-control list-search !tw-h-[60px] !tw-pl-0"
              type="search"
              placeholder="Search message"
              @input="onConversationSearch"
            />
          </div>
          <div class="tw-flex tw-flex-1 tw-justify-center tw-items-center">
            <span
              class="tw--mt-1 tw-cursor-pointer"
              @click.prevent="previousConversationList"
              v-if="$store.state.message.currentPage > 1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="width: 20px; height: 20px"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#ff7a37"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </span>
            <span>{{ $store.state.message.currentPage }}</span>
            <span
              class="tw--mt-1 tw-cursor-pointer"
              @click.prevent="nextConversationList"
              v-if="
                !(
                  $store.state.message.currentPage ===
                  $store.state.message.pageCount
                )
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="width: 20px; height: 20px"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#ff7a37"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="tw-chat-list-content tw-w-full">
          <div
            class="tw-flex tw-items-center tw-px-6 tw-py-4"
            v-for="(conv, i) in conversationlist"
            :key="`${conv.id}-${i}`"
            @click="setConversationId(conv)"
            :class="[conversation.id == conv.id ? 'patient-selected' : '']"
          >
            <b-avatar
              variant="tertiary"
              :src="
                Array.isArray(conv.profileUrl)
                  ? conv.profileUrl[0]
                  : conv.profileUrl
              "
              class="tw-flex-none"
            ></b-avatar>
            <div
              class="
                tw-flex
                tw-flex-col
                tw-justify-center
                tw-flex-1
                tw-min-w-0
                tw-pl-4
              "
            >
              <h3
                class="tw-mt-0 tw-truncate"
                :class="conv.lastmessage ? 'tw-mb-1' : 'tw-mb-0'"
              >
                {{ conv.name }}
              </h3>
              <p class="tw-mb-0 tw-truncate" v-if="conv.lastmessage">
                {{ conv.lastmessage }}
              </p>
            </div>
            <b-badge
              v-if="conv.needspatientreply"
              style="padding: 5px; border-radius: 9999px; display: inline-block"
              variant="secondary"
            ></b-badge>
          </div>

          <div class="text-center" v-if="$route.query.patientid">
            <a
              @click.prevent="onLoadAllConversations"
              class="py-3 d-inline-block"
              style="cursor: pointer"
            >
              Load all conversations
            </a>
          </div>
        </div>
      </li>
      <li
        class="tw-chat-box tw-absolute tw-inset-0 tw-z-20 tw-bg-[#1a1a1a]"
        v-if="conversationpatient.id != ''"
      >
        <div
          class="
            card-chat-body
            tw-flex tw-overflow-y-auto tw-items-start tw-flex-col-reverse
          "
          :style="{ height: mHeight - mobileHeight + 'px' }"
          @scroll.passive="messageScroll"
        >
          <div class="chat-box">
            <div
              class="chat-inner"
              :class="{ 'mb-6': $_orientation == 'landscape' }"
            >
              <div
                v-for="(message, i) in messages"
                :key="i"
                class="tw-items-center px-4"
                :class="{
                  'tw-flex': isMarkMessagesEnabled,
                  'tw-justify-between': message.userid == userid,
                }"
              >
                <div
                  :key="'checkbox-' + i"
                  class="tw-mr-2"
                  v-if="isMarkMessagesEnabled"
                >
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      :checked="isChecked(message)"
                      class="forward-checkbox custom-control-input"
                      :id="'checkbox-' + i"
                      :name="'checkbox-' + i"
                      @change="(e) => onForwardCheckboxChange(e, message)"
                    />
                    <label
                      :for="'checkbox-' + i"
                      class="custom-control-label"
                    ></label>
                  </div>
                </div>
                <div :key="i" v-if="message.type === 'prescription_followup'">
                  <b-card class="followup-update text-center mt-3 p-3 mb-3">
                    <p class="mb-0" v-if="message.actionstatus === 'PENDING'">
                      <span class="d-block mb-1" style="text-align: center">
                        Follow-up questions has been sent to the patient
                      </span>
                      <small class="d-block text-left time-stamp-followup">
                        Time sent:
                        {{
                          moment
                            .unix(message.sentat)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </small>
                    </p>
                    <p class="mb-0" v-if="message.actionstatus === 'ANSWERED'">
                      <span class="d-block mb-1" style="text-align: center">
                        Follow-up questions has been answered
                      </span>
                      <small class="d-block text-left time-stamp-followup mb-2">
                        Time sent:
                        {{
                          moment
                            .unix(message.sentat)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </small>
                      <small class="d-block text-left time-stamp-followup">
                        Time answered:
                        {{
                          moment
                            .unix(
                              message.custommessage.message.timestampanswered
                            )
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </small>
                    </p>
                  </b-card>
                </div>
                <div
                  :key="`readmission_transfer-${i}`"
                  v-else-if="message.type === 'reassignment_transfer'"
                >
                  <b-card class="followup-update mt-3 p-3 mb-3">
                    <p class="mb-0">
                      <span class="d-block mb-1">
                        Patient has been reassigned to
                        <span
                          v-if="
                            message.custommessage.message.doctoridto === userid
                          "
                        >
                          you from Dr.
                          {{ message.custommessage.message.doctorfrom }}
                        </span>
                        <span v-else>
                          Dr.
                          {{ message.custommessage.message.doctorto }}
                        </span>
                        <span
                          v-if="
                            message.custommessage.message.reassignmentstatus !==
                            'RETURNED'
                          "
                          >{{
                            message.custommessage.message.assignmenttype ===
                            "TEMPORARY"
                              ? "temporarily"
                              : "permanently"
                          }}</span
                        >
                      </span>
                      <small
                        v-if="
                          message.custommessage.message.assignmenttype ===
                            'TEMPORARY' &&
                          message.custommessage.message.reassignmentstatus !==
                            'RETURNED'
                        "
                        class="d-block text-left time-stamp-followup"
                      >
                        {{
                          moment
                            .unix(message.custommessage.message.startdatestamp)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                        to
                        {{
                          moment
                            .unix(message.custommessage.message.enddatestamp)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </small>
                    </p>
                  </b-card>
                </div>

                <div v-else-if="message.type === 'prescription_confirmation'">
                  <b-card
                    class="treatment-update text-center mt-3 mb-3"
                    style="background-color: #e9f5f3"
                  >
                    <h2 class="mb-2">
                      Updated Treatment:
                      {{ message.custommessage.message.productname }}
                    </h2>

                    <p class="mb-0">
                      The price of
                      {{ message.custommessage.message.productname }}
                      treatment has changed from ${{
                        message.custommessage.message.pricefrom
                      }}
                      to ${{ message.custommessage.message.priceto }}.
                    </p>
                    <p v-if="message.actionstatus === 'PENDING'" class="mb-0">
                      Awaiting confirmation from patient
                    </p>
                    <p v-if="message.actionstatus === 'ACCEPTED'" class="mb-0">
                      Prescription has been accepted by patient
                    </p>
                    <p v-if="message.actionstatus === 'DECLINED'" class="mb-0">
                      Prescription has been declined by patient
                    </p>
                  </b-card>
                </div>
                <div
                  v-else
                  :class="[
                    message.userid != userid ? 'patient' : 'doctor',
                    'chat-bubble',
                  ]"
                >
                  <div class="chat-img">
                    <img :src="getMemberProfileUrl(message.userid)" alt="" />
                  </div>
                  <div
                    :class="[
                      message.userid != userid ? 'patient' : 'doctor',
                      'chat',
                      isAdminView && message.userid != userid ? 'admin' : '',
                    ]"
                  >
                    <div
                      v-if="message.attachment && message.attachment.length > 0"
                    >
                      <div
                        v-for="(chatAttachment, i) in message.attachment"
                        :key="i"
                        style="background-color: transparent"
                      >
                        <b-row
                          no-gutters
                          v-if="
                            chatAttachment.type.includes('image') &&
                            !['tiff', 'heic', 'svg'].some((type) =>
                              chatAttachment.type.includes(type)
                            )
                          "
                        >
                          <b-col>
                            <a
                              :href="chatAttachment.url"
                              variant="link"
                              download
                              target="_blank"
                            >
                              <b-img
                                thumbnail
                                fluid
                                :src="chatAttachment.url"
                                width="250px"
                              ></b-img>
                            </a>
                          </b-col>
                        </b-row>
                        <b-row no-gutters v-else>
                          <a :href="chatAttachment.url" variant="link" download
                            ><b-icon
                              icon="arrow-down-circle-fill"
                              variant="primary"
                            ></b-icon>
                            {{ chatAttachment.filename }}</a
                          >
                        </b-row>
                      </div>
                    </div>
                    <span v-if="message.message !== 'null'">
                      {{ message.message }} </span
                    ><br />
                    <small
                      :class="[
                        message.userid != userid ? 'patient' : 'doctor',
                        'time-stamp',
                      ]"
                      class="tw-flex tw-flex-col"
                    >
                      <span
                        v-if="message.userid != userid && isAdminView"
                        class="mb-1"
                      >
                        {{ getAdminName(message.userid) }}
                      </span>
                      <span>
                        {{
                          moment
                            .unix(message.sentat)
                            .format("MMM DD, YYYY hh:mm A")
                        }}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="conversationpatient.needsPatientReply && !isAdminView"
          class="tw-text-center text-secondary"
        >
          Needs patient reply
        </div>
        <div
          class="
            tw-chat-footer
            tw-border-0
            tw-border-t
            tw-border-[#E0E0E0]
            tw-border-opacity-[0.5]
            tw-border-solid
          "
          v-resize="observeMobileChat"
          v-if="!currentDoctor.id"
        >
          <div class="pre-upload" v-if="preUpload">
            <div
              class="img-holder"
              v-for="(attachtment, index) in attachtments"
              :key="index"
            >
              <b-icon
                icon="x-circle-fill"
                class="close-btn"
                @click="removeFile(index)"
              ></b-icon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                v-if="
                  !attachtment.type.includes('image') ||
                  attachtment.type.includes('heic')
                "
              >
                <path
                  fill="currentColor"
                  d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
                />
              </svg>
              <img v-else :src="attachtment.url" :alt="attachtment.name" />
            </div>
          </div>
          <div
            :class="{
              'tw-flex': $_orientation == 'landscape',
              'lg:tw-block': $_orientation == 'landscape',
            }"
          >
            <div
              class="tw-flex tw-items-center"
              :class="{ 'tw-grow': $_orientation == 'landscape' }"
            >
              <div class="input-group input-group-flush tw-pt-[20px]">
                <b-form-textarea
                  no-resize
                  max-rows="5"
                  v-model="newMessage"
                  ref="messageArea"
                  placeholder="Write a message..."
                ></b-form-textarea>
              </div>
              <div class="tw-flex-initial">
                <b-button variant="link" @click="openUpload">
                  <IconPaperclip class="tw-w-5 tw-h-5 tw-text-gray-800" />
                </b-button>
                <input
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  multiple
                  v-on:change="handleFilesUpload()"
                />
              </div>
              <div class="tw-flex-initial">
                <b-button variant="link" @click="showCannedReplyModal = true">
                  <IconCannedReply class="tw-w-5 tw-h-5 tw-text-gray-800" />
                </b-button>
              </div>
            </div>
            <div class="tw-flex tw-p-2 tw-space-x-2">
              <b-button
                variant="danger"
                pill
                block
                style="line-height: 0"
                @click="sendMessageNeedsReply"
                v-if="!isAdminView"
                :disabled="isMessageBodyEmpty"
              >
                <span class="tw-text-xs"> Send Message </span><br />
                <span class="tw-text-xs"> Needs Reply </span>
              </b-button>
              <b-button
                variant="primary"
                pill
                block
                style="line-height: 0"
                @click="() => sendMessage()"
                :disabled="isMessageBodyEmpty"
              >
                <span class="tw-text-xs"> Send Message </span>
              </b-button>
            </div>
          </div>
        </div>
      </li>
      <li
        v-if="showInformation"
        class="
          tw-patient-information tw-absolute tw-inset-0 tw-z-30 tw-overflow-auto
        "
      >
        <b-card class="patient-info mb-0" v-if="conversationpatient.id != ''">
          <b-row>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="auto">
                  <b-avatar
                    size="85px"
                    :src="conversationpatient.profileUrl"
                  ></b-avatar>
                </b-col>
                <b-col>
                  <h3 class="bold text-black">
                    {{ conversationpatient.firstName }}
                    {{ conversationpatient.lastName }}
                  </h3>
                  <div>
                    <b-button
                      pill
                      size="sm"
                      variant="outline-dark"
                      block
                      class="d-flex align-items-center justify-content-center"
                      @click="showNotesModal = true"
                    >
                      <v-icon
                        name="note"
                        variant=""
                        size="20"
                        color="currentColor"
                      />
                      <span class="d-inline-block ml-2"> Notes </span>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      variant="outline-dark"
                      block
                      class="d-flex align-items-center justify-content-center"
                      v-if="!currentDoctor.id"
                    >
                      <v-icon name="reminder" color="currentColor" variant="" />
                      <span>Set Reminder</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="text-muted mb-2">
                    <small>Condition</small>
                  </p>
                  <h4 class="text-black">{{ conversationpatient.symptoms }}</h4>
                  <h4 v-if="!conversationpatient.symptoms" class="text-muted">
                    No records!
                  </h4>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="text-muted mb-2">
                    <small>Other Medications</small>
                  </p>
                  <div v-if="conversationpatient.otherMedication">
                    <h4
                      v-for="(
                        medication, i
                      ) in conversationpatient.otherMedication"
                      :key="i"
                    >
                      {{ medication }}
                    </h4>
                  </div>
                  <h4 v-else class="text-muted">No records!</h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <h4 class="mb-0 bold">Treatment Plans</h4>
          <b-list-group flush>
            <b-list-group-item
              v-for="(treat, a) in conversationpatient.treatmentPlanDetail"
              :key="a"
            >
              <b-row>
                <b-col cols="12" class="mb-2">
                  <b-row>
                    <b-col>
                      <small>{{ treat.description }}</small>
                    </b-col>
                    <b-col cols="auto">
                      <small>{{
                        moment.unix(treat.date).format("MMMM DD, YYYY")
                      }}</small>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-badge
                        style="
                          padding: 5px;
                          border-radius: 9999px;
                          display: inline-block;
                        "
                        :class="[
                          'mr-2',
                          treat.status == 'PAUSED'
                            ? 'badge-warning'
                            : treat.status == 'CANCELLED'
                            ? 'badge-danger'
                            : treat.status == 'ACCEPTED'
                            ? 'badge-success'
                            : treat.status == 'PRESCRIBED'
                            ? 'badge-success'
                            : '',
                        ]"
                      ></b-badge>
                      <small>{{ treat.status }}</small>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                conversationpatient.treatmentPlanDetail != undefined &&
                conversationpatient.treatmentPlanDetail.length == 0
              "
            >
              <p class="text-muted mb-0">No Medication</p>
            </b-list-group-item>
          </b-list-group>
          <template v-slot:footer v-if="!currentDoctor.id">
            <b-button
              pill
              variant="secondary"
              block
              :to="`/prescriptions/order/${conversationpatient.userId}`"
              v-if="!conversationpatient.isBanned"
            >
              Create a Plan
            </b-button>
            <b-button
              pill
              variant="outline-secondary"
              :to="`/patients/profile/${conversationpatient.userId}`"
              block
            >
              View Patient Profile
            </b-button>
            <b-button
              pill
              variant="outline-secondary"
              block
              v-b-modal.viewDosespotFromMessage
            >
              Open Dosespot
            </b-button>
          </template>
        </b-card>
      </li>
      <div ref="bottomOfPage" id="bottomOfPage"></div>
    </ul>
    <b-row class="content d-none d-xl-flex mx-4">
      <!-- LIST OF PATIENT -->
      <b-col cols="12" lg="3" class="pr-0">
        <b-card class="chat-patient-list" no-body>
          <template v-slot:header>
            <b-row>
              <b-col>
                <div class="input-group input-group-flush">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fe fe-search text-primary"></i>
                    </span>
                  </div>
                  <input
                    v-model="filter"
                    class="form-control list-search"
                    type="search"
                    placeholder="Search"
                    @input="onConversationSearch"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  class="
                    px-2
                    py-2
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <a
                    href=""
                    @click.prevent="previousConversationList"
                    v-if="$store.state.message.currentPage > 1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 20px; height: 20px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </a>
                  <small style="line-height: 0" class="text-muted px-1">
                    Page {{ $store.state.message.currentPage }}
                  </small>
                  <a
                    href=""
                    @click.prevent="nextConversationList"
                    v-if="
                      $store.state.message.pageCount >
                      $store.state.message.currentPage
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 20px; height: 20px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </a>
                </div>
              </b-col>
            </b-row>
          </template>
          <ul class="list-unstyled" ref="conversationListRef">
            <li
              v-for="(conv, i) in conversationlist"
              v-bind:key="`${conv.id}-${i}`"
              @click="setConversationId(conv)"
              :class="[
                conversation.id == conv.id ? 'selected' : '',
                'patient-list',
              ]"
            >
              <div class="list-img">
                <b-avatar
                  variant="tertiary"
                  :src="
                    Array.isArray(conv.profileUrl)
                      ? conv.profileUrl[0]
                      : conv.profileUrl
                  "
                ></b-avatar>
              </div>
              <div class="list-content">
                <template>
                  <h3 class="mt-0 mb-1 text-capitalize">{{ conv.name }}</h3>
                  <p class="mb-0 text-truncate">
                    {{ conv.lastmessage }}
                  </p>
                </template>
              </div>
              <!-- once the patient has new message this badge will display uncomment to check the display -->
              <b-badge
                v-if="conv.needspatientreply"
                style="
                  padding: 5px;
                  border-radius: 9999px;
                  display: inline-block;
                "
                variant="secondary"
              ></b-badge>
            </li>
            <li class="text-center" v-if="$route.query.patientid">
               
              <a
                @click.prevent="onLoadAllConversations"
                class="py-3 d-inline-block"
                style="cursor: pointer"
              >
                Load all conversations
              </a>
            </li>
          </ul>
        </b-card>
      </b-col>
      <!-- FOR DESKTOP VIEW -->
      <b-col
        class="d-none d-sm-none d-lg-block"
        cols="12"
        :lg="conversationpatient.id == '' ? '9' : '6'"
      >
        <template v-if="conversationpatient.id != ''">
          <b-card
            no-body
            class="mb-0 chats"
            style="
              border-left: 0;
              border-right: 0;
              border-bottom: 0;
              height: calc(100vh - 200px);
            "
          >
            <template v-slot:header>
              <div class="tw-flex tw-justify-between tw-items-center">
                <h3 class="mb-0">
                  <span class="tw-text-lg" v-if="isAdminView">Admin for </span>
                  {{ conversationpatient.firstName }}
                  {{ conversationpatient.lastName }}
                </h3>
                <div>
                  <b-button
                    v-if="!isAdminView"
                    pill
                    variant="link"
                    class="text-primary tw-underline tw-mr-4"
                    @click="isAdminView = true"
                    size="sm"
                  >
                    Admin
                  </b-button>
                  <b-button
                    v-if="isAdminView"
                    pill
                    variant="link"
                    class="text-primary tw-underline tw-mr-4"
                    @click="isAdminView = false"
                    size="sm"
                  >
                    Patient
                  </b-button>
                  <b-button
                    v-if="isMarkMessagesEnabled && !isAdminView"
                    pill
                    variant="link"
                    class="text-secondary"
                    size="sm"
                    @click="onCancelMarkMessages"
                  >
                    Cancel
                  </b-button>
                  <b-dropdown
                    v-if="!isAdminView"
                    id="forward-dropdown"
                    right
                    split
                    variant="primary"
                    :split-variant="
                      forwardRequestCounter === 1
                        ? 'primary'
                        : 'outline-primary'
                    "
                    text="Forward"
                    size="sm"
                    @click="onForwardRequest"
                  >
                    <b-dropdown-item
                      @click="$bvModal.show('viewForwardMessage')"
                    >
                      View Forward Requests
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
            <b-card-body
              body-class="card-chat-body pb-2"
              @scroll.passive="messageScroll"
              :style="{
                height: currentDoctor.id
                  ? 'calc(100vh - 260px)'
                  : height - messageHeight - 75 + 'px',
              }"
            >
              <div :class="['chat-box']" id="chat-box">
                <div class="chat-inner">
                  <div
                    v-for="(message, i) in messages"
                    :key="'parent-' + i"
                    class="tw-flex tw-items-center"
                    :class="{
                      'selected-message': selectedMessages.some(
                        (msg) => msg.id === message.id
                      ),
                    }"
                    style="padding: 0 1.5rem"
                  >
                    <div
                      :key="'checkbox-' + i"
                      class="tw-mr-2"
                      v-if="isMarkMessagesEnabled"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          :checked="isChecked(message)"
                          class="forward-checkbox custom-control-input"
                          :id="'checkbox-' + i"
                          :name="'checkbox-' + i"
                          @change="(e) => onForwardCheckboxChange(e, message)"
                        />
                        <label
                          :for="'checkbox-' + i"
                          class="custom-control-label"
                        ></label>
                      </div>
                    </div>
                    <div
                      :key="i"
                      v-if="message.type === 'prescription_followup'"
                    >
                      <b-card class="followup-update text-center mt-3 p-3 mb-3">
                        <p
                          class="mb-0"
                          v-if="message.actionstatus === 'PENDING'"
                        >
                          <span class="d-block mb-1" style="text-align: center">
                            Follow-up questions has been sent to the patient
                          </span>
                          <small class="d-block text-left time-stamp-followup">
                            Time sent:
                            {{
                              moment
                                .unix(message.sentat)
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                          </small>
                        </p>
                        <p
                          class="mb-0"
                          v-if="message.actionstatus === 'ANSWERED'"
                        >
                          <span class="d-block mb-1" style="text-align: center">
                            Follow-up questions has been answered
                          </span>
                          <small
                            class="d-block text-left time-stamp-followup mb-2"
                          >
                            Time sent:
                            {{
                              moment
                                .unix(message.sentat)
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                          </small>
                          <small class="d-block text-left time-stamp-followup">
                            Time answered:
                            {{
                              moment
                                .unix(
                                  message.custommessage.message
                                    .timestampanswered
                                )
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                          </small>
                        </p>
                      </b-card>
                    </div>
                    <div
                      :key="`reassignment_transfer-${i}`"
                      v-else-if="message.type === 'reassignment_transfer'"
                    >
                      <b-card class="followup-update mt-3 p-3 mb-3">
                        <p class="mb-0">
                          <span class="d-block mb-1">
                            Patient has been reassigned to
                            <span
                              v-if="
                                message.custommessage.message.doctoridto ===
                                userid
                              "
                            >
                              you from Dr.
                              {{ message.custommessage.message.doctorfrom }}
                            </span>
                            <span v-else>
                              Dr.
                              {{ message.custommessage.message.doctorto }}
                            </span>
                            <span
                              v-if="
                                message.custommessage.message
                                  .reassignmentstatus !== 'RETURNED'
                              "
                              >{{
                                message.custommessage.message.assignmenttype ===
                                "TEMPORARY"
                                  ? "temporarily"
                                  : "permanently"
                              }}</span
                            >
                          </span>
                          <small
                            v-if="
                              message.custommessage.message.assignmenttype ===
                                'TEMPORARY' &&
                              message.custommessage.message
                                .reassignmentstatus !== 'RETURNED'
                            "
                            class="d-block text-left time-stamp-followup"
                          >
                            {{
                              moment
                                .unix(
                                  message.custommessage.message.startdatestamp
                                )
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                            to
                            {{
                              moment
                                .unix(
                                  message.custommessage.message.enddatestamp
                                )
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                          </small>
                        </p>
                      </b-card>
                    </div>

                    <div
                      :key="`prescription_confirmation-${i}`"
                      v-else-if="message.type === 'prescription_confirmation'"
                    >
                      <b-card
                        class="treatment-update text-center mt-3 mb-3"
                        style="background-color: #e9f5f3"
                      >
                        <h2 class="mb-2">
                          Updated Treatment:
                          {{ message.custommessage.message.productname }}
                        </h2>
                        <p class="mb-0">
                          The price of
                          {{ message.custommessage.message.productname }}
                          treatment has changed from ${{
                            message.custommessage.message.pricefrom
                          }}
                          to ${{ message.custommessage.message.priceto }}.
                        </p>
                        <p
                          v-if="message.actionstatus === 'PENDING'"
                          class="mb-0"
                        >
                          Awaiting confirmation from patient
                        </p>
                        <p
                          v-if="message.actionstatus === 'ACCEPTED'"
                          class="mb-0"
                        >
                          Prescription has been accepted by patient
                        </p>
                        <p
                          v-if="message.actionstatus === 'DECLINED'"
                          class="mb-0"
                        >
                          Prescription has been declined by patient
                        </p>
                      </b-card>
                    </div>
                    <div
                      :key="`forward_message-${i}`"
                      v-else-if="message.type === 'forward_message'"
                      class="tw-flex-1"
                      :class="[
                        message.userid != userid ? 'patient' : 'doctor',
                        'chat-bubble',
                      ]"
                    >
                      <div class="chat-img">
                        <img
                          :src="getMemberProfileUrl(message.userid)"
                          alt=""
                        />
                      </div>
                      <div
                        class="tw-relative doctor chat tw-ml-[10px]"
                        :style="
                          message.custommessage.forwardmessage.messagestatus ===
                          'COMPLETED'
                            ? 'background-color: #F5FEEF'
                            : 'background-color: rgba(26, 106, 114, 0.05)'
                        "
                      >
                        <div class="tw-text-right">
                          <a
                            @click.prevent="
                              $refs.viewForwardMessages.onView(
                                message.custommessage.forwardmessage.id,
                                message.custommessage.forwardmessage
                                  .conversationid
                              )
                            "
                            class="text-secondary tw-cursor-pointer"
                          >
                            View
                          </a>
                        </div>
                        <div
                          v-for="chat in message.custommessage.forwardmessage.messagelist
                            .slice()
                            .reverse()"
                          :key="chat.id"
                          class="tw-mb-2"
                        >
                          <small class="text-muted tw-text-[11px] tw-block">
                            Sent by
                            {{ `${chat.firstname} ${chat.lastname}` }} at
                            {{
                              moment
                                .unix(chat.sentat)
                                .format("MMM DD, YYYY hh:mm A")
                            }}
                          </small>
                          <div
                            class="tw-px-4 tw-py-2 tw-bg-white tw-inline-block"
                            :style="
                              chat.usertype === 'PATIENT'
                                ? 'background-color: #FFECCA'
                                : ''
                            "
                          >
                            <div
                              v-if="
                                chat.attachment && chat.attachment.length > 0
                              "
                            >
                              <div
                                v-for="(chatAttachment, i) in chat.attachment"
                                :key="i"
                                style="background-color: transparent"
                              >
                                <b-row
                                  no-gutters
                                  v-if="
                                    chatAttachment.type != 'application/pdf'
                                  "
                                >
                                  <b-col>
                                    <a
                                      :href="chatAttachment.url"
                                      variant="link"
                                      download
                                      target="_blank"
                                    >
                                      <b-img
                                        thumbnail
                                        fluid
                                        :src="chatAttachment.url"
                                        width="250px"
                                      ></b-img>
                                    </a>
                                  </b-col>
                                </b-row>
                                <b-row no-gutters v-else>
                                  <a
                                    :href="chatAttachment.url"
                                    variant="link"
                                    download
                                  >
                                    <b-icon
                                      icon="arrow-down-circle-fill"
                                      variant="primary"
                                    ></b-icon>
                                    {{ chatAttachment.filename }}
                                  </a>
                                </b-row>
                              </div>
                            </div>
                            {{ chat.message }}
                          </div>
                        </div>
                        <div
                          class="
                            tw-text-center tw-mt-2 tw-text-sm tw-text-green-900
                          "
                          v-if="
                            message.custommessage.forwardmessage
                              .messagestatus === 'COMPLETED'
                          "
                        >
                          {{
                            message.custommessage.forwardmessage.messagestatus
                          }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      :key="`chat-${i}`"
                      class="tw-flex-1"
                      :class="[
                        message.userid != userid ? 'patient' : 'doctor',
                        'chat-bubble',
                      ]"
                    >
                      <div class="chat-img">
                        <img
                          :src="getMemberProfileUrl(message.userid)"
                          alt=""
                        />
                      </div>
                      <div
                        :class="[
                          message.userid != userid ? 'patient' : 'doctor',
                          'chat',
                          isAdminView && message.userid != userid
                            ? 'admin'
                            : '',
                        ]"
                        class="tw-relative"
                      >
                        <div
                          v-if="
                            message.attachment && message.attachment.length > 0
                          "
                        >
                          <div
                            v-for="(chatAttachment, i) in message.attachment"
                            :key="i"
                            style="background-color: transparent"
                          >
                            <b-row
                              no-gutters
                              v-if="chatAttachment.type.includes('image')"
                            >
                              <b-col>
                                <a
                                  :href="chatAttachment.url"
                                  variant="link"
                                  download
                                  target="_blank"
                                >
                                  <b-img
                                    thumbnail
                                    fluid
                                    :src="chatAttachment.url"
                                    width="250px"
                                  ></b-img>
                                </a>
                              </b-col>
                            </b-row>
                            <b-row no-gutters v-else>
                              <a
                                :href="chatAttachment.url"
                                variant="link"
                                download
                                ><b-icon
                                  icon="arrow-down-circle-fill"
                                  variant="primary"
                                ></b-icon>
                                {{ chatAttachment.filename }}</a
                              >
                            </b-row>
                          </div>
                        </div>
                        <span v-if="message.message !== 'null'">
                          {{ message.message }}
                        </span>
                        <small
                          :class="[
                            message.userid != userid ? 'patient' : 'doctor',
                            'time-stamp',
                          ]"
                          class="
                            tw-absolute
                            tw-left-0
                            tw-flex
                            tw-flex-col
                            tw-items-start
                            tw-w-[150px]
                          "
                          :style="{
                            bottom:
                              message.userid != userid && isAdminView
                                ? '-24px'
                                : '-14px',
                          }"
                        >
                          <span v-if="message.userid != userid && isAdminView">
                            {{ getAdminName(message.userid) }}
                          </span>

                          <div class="tw-flex tw-gap-3">
                            <span>
                              {{
                                moment
                                  .unix(message.sentat)
                                  .format("MMM DD, YYYY hh:mm A")
                              }}
                            </span>

                            <ChatRoutedTooltip :message="message" />
                          </div>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-footer
              :footer-class="`custom-footer tw-relative ${
                isAdminView ? 'tw-mt-8' : ''
              }`"
              v-resize="observeTextarea"
              v-if="!currentDoctor.id"
            >
              <div
                class="text-danger tw-top-[-25px]"
                v-if="isAdminView"
                :class="{ 'tw-absolute': isAdminView }"
              >
                Your message will be sent to the admin
              </div>
              <div class="pre-upload">
                <div
                  class="img-holder"
                  v-for="(attachtment, index) in attachtments"
                  :key="index"
                >
                  <b-icon
                    icon="x-circle-fill"
                    class="close-btn"
                    @click="removeFile(index)"
                  ></b-icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    v-if="
                      !attachtment.type.includes('image') ||
                      attachtment.type.includes('heic')
                    "
                  >
                    <path
                      fill="currentColor"
                      d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
                    />
                  </svg>
                  <img v-else :src="attachtment.url" :alt="attachtment.name" />
                </div>
              </div>
              <b-row class="mr-0">
                <b-col>
                  <div class="input-group input-group-flush">
                    <b-form-textarea
                      max-rows="7"
                      no-resize
                      v-model="newMessage"
                      ref="messageArea"
                      placeholder="Write a message..."
                    ></b-form-textarea>
                  </div>
                </b-col>
                <b-col cols="auto" class="px-0">
                  <b-button variant="link" @click="openUpload">
                    <IconPaperclip class="tw-w-5 tw-h-5 tw-text-gray-800" />
                  </b-button>
                  <input
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    multiple
                    v-on:change="handleFilesUpload()"
                  />
                </b-col>
                <b-col cols="auto" class="px-0">
                  <b-button variant="link" @click="showCannedReplyModal = true">
                    <IconCannedReply class="tw-w-5 tw-h-5 tw-text-gray-800" />
                  </b-button>
                </b-col>
                <b-col cols="auto" class="px-0">
                  <b-button
                    variant="primary"
                    pill
                    block
                    @click="() => sendMessage()"
                    :disabled="isMessageBodyEmpty"
                  >
                    <span class="tw-text-xs"> Send Message </span>
                  </b-button>
                  <b-button
                    variant="danger"
                    pill
                    block
                    style="line-height: 0"
                    @click="sendMessageNeedsReply"
                    v-if="!isAdminView"
                    :disabled="isMessageBodyEmpty"
                  >
                    <span class="tw-text-xs"> Send Message </span><br />
                    <span class="tw-text-xs"> Needs Reply </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </template>
        <template v-if="conversationpatient.id == ''">
          <b-card
            no-body
            class="mb-0"
            style="
              background-color: transparent;
              border-left: 0;
              border-right: 0;
            "
          >
            <template v-slot:header>
              <b-row align-v="center">
                <b-col>
                  <h3 class="mb-0">Conversation</h3>
                </b-col>
              </b-row>
            </template>
            <div
              class="chat-box"
              style="
                justify-content: center;
                align-items: center;
                height: calc(80vh - 232px);
              "
            >
              <h3 v-if="loading" class="text-muted">
                Loading conversation details
              </h3>
              <h3 v-else class="text-muted">
                Select patient to view conversation
              </h3>
            </div>
          </b-card>
        </template>
      </b-col>
      <b-col
        class="d-none d-sm-none d-lg-block"
        v-if="conversationpatient.id != ''"
        cols="12"
        lg="3"
      >
        <b-card class="patient-info mb-0" v-if="conversationpatient.id != ''">
          <template v-slot:header>
            <b-row align-v="center">
              <b-col>
                <h3 class="mb-0 text-black">Patient Information</h3>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="auto">
                  <b-skeleton
                    animation="fade"
                    v-if="!conversationpatient.profileUrl"
                    type="avatar"
                    size="85px"
                  ></b-skeleton>
                  <b-avatar
                    size="85px"
                    :src="conversationpatient.profileUrl"
                    v-else
                  ></b-avatar>
                </b-col>
                <div class="w-100 d-lg-none"></div>
                <b-col>
                  <h3 class="bold text-black">
                    {{ conversationpatient.firstName }}
                    {{ conversationpatient.lastName }}
                  </h3>
                  <div>
                    <b-button
                      pill
                      size="sm"
                      variant="outline-dark"
                      block
                      class="d-flex align-items-center justify-content-center"
                      @click="showNotesModal = true"
                    >
                      <v-icon
                        name="note"
                        variant=""
                        size="20"
                        color="currentColor"
                      />
                      <span class="d-inline-block ml-2"> Notes </span>
                    </b-button>
                    <b-button
                      pill
                      size="sm"
                      variant="outline-dark"
                      block
                      class="d-flex align-items-center justify-content-center"
                      @click="$bvModal.show('setReminder')"
                      v-if="!currentDoctor.id"
                    >
                      <v-icon name="reminder" color="currentColor" variant="" />
                      <span>Set Reminder</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="6">
                  <p class="text-muted mb-2">
                    <small>Date of Birth</small>
                  </p>
                  <h4 class="text-black">
                    {{ parseBirthdate(conversationpatient.birthday) }}
                  </h4>
                </b-col>
                <b-col cols="12" md="3">
                  <p class="text-muted mb-2">
                    <small>Age</small>
                  </p>
                  <h4 class="text-black">{{ conversationpatient.age }}</h4>
                </b-col>
                <b-col cols="12" md="3">
                  <p class="text-muted mb-2">
                    <small>State</small>
                  </p>
                  <h4 class="text-black">{{ conversationpatient.state }}</h4>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="text-muted mb-2">
                    <small>Condition</small>
                  </p>
                  <h4 class="text-black">{{ conversationpatient.symptoms }}</h4>
                  <h4 v-if="!conversationpatient.symptoms" class="text-muted">
                    No records!
                  </h4>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="text-muted mb-2">
                    <small>Other Medications</small>
                  </p>
                  <div v-if="conversationpatient.otherMedication">
                    <h4
                      class="text-black"
                      v-for="(
                        medication, i
                      ) in conversationpatient.otherMedication"
                      :key="i"
                    >
                      {{ medication }}
                    </h4>
                  </div>
                  <h4 v-else class="text-black">No records!</h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <h3 class="mb-0 text-black" style="font-size: 16px">
            Treatment Plans
          </h3>
          <b-list-group flush>
            <b-list-group-item
              v-for="(treat, a) in conversationpatient.treatmentPlanDetail"
              :key="a"
            >
              <b-row>
                <b-col cols="12" class="mb-2">
                  <b-row>
                    <b-col>
                      <small class="text-black" style="font-size: 14px">{{
                        treat.description
                      }}</small>
                    </b-col>
                    <b-col cols="auto">
                      <small>{{
                        moment.unix(treat.date).format("MM/DD/YY")
                      }}</small>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-badge
                        style="
                          padding: 5px;
                          border-radius: 9999px;
                          display: inline-block;
                        "
                        :class="[
                          'mr-2',
                          treat.status == 'PAUSED'
                            ? 'badge-warning'
                            : treat.status == 'CANCELLED'
                            ? 'badge-danger'
                            : treat.status == 'ACCEPTED'
                            ? 'badge-success'
                            : treat.status == 'PRESCRIBED'
                            ? 'badge-success'
                            : '',
                        ]"
                      ></b-badge>
                      <small>{{ treat.status }}</small>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="
                conversationpatient.treatmentPlanDetail != undefined &&
                conversationpatient.treatmentPlanDetail.length == 0
              "
            >
              <p class="text-muted mb-0">No Medication</p>
            </b-list-group-item>
          </b-list-group>
          <b-row class="mt-3" v-if="conversationpatient.patientNotes">
            <b-col cols="12" class="mb-3">
              <b-row align-v="center">
                <b-col cols="12" md="12">
                  <p class="text-muted mb-2">
                    <small>Patient Notes</small>
                  </p>
                  <h4 class="text-black">
                    {{ conversationpatient.patientNotes }}
                  </h4>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <template v-slot:footer>
            <b-button
              pill
              variant="secondary"
              block
              :to="`/prescriptions/order/${conversationpatient.userId}`"
              v-if="!currentDoctor.id && conversationpatient.isBanned"
            >
              Create a Plan
            </b-button>
            <b-button
              pill
              variant="outline-secondary"
              :to="`/patients/profile/${conversationpatient.userId}`"
              block
            >
              View Patient Profile
            </b-button>
            <b-button
              pill
              variant="outline-secondary"
              block
              v-b-modal.viewDosespotFromMessage
              v-if="!currentDoctor.id"
            >
              Open Dosespot
            </b-button>
            <template v-if="!currentDoctor.id">
              <b-button
                variant="link"
                block
                class="pb-0"
                @click="onNeedPatientReply"
                style="box-shadow: none !important"
                v-if="!conversationpatient.needsPatientReply"
              >
                <span class="text-secondary d-inline-block btn-patient-reply">
                  Mark Needs Reply
                </span>
                <br />
              </b-button>
              <b-button
                variant="link"
                block
                class="pb-0"
                @click="onRemoveNeedPatientReply"
                style="box-shadow: none !important"
                v-else
              >
                <span class="text-secondary d-inline-block btn-patient-reply">
                  Clear Needs Reply
                </span>
                <br />
              </b-button>
            </template>
            <div
              class="text-black text-center patient-reply-description"
              v-if="patientLastReplyDate && !currentDoctor.id"
            >
              <small>
                This patient hasn’t replied for
                <strong>
                  {{ patientLastReplyDate }}
                </strong>
              </small>
            </div>
          </template>
        </b-card>
        <!-- </template> -->
      </b-col>
    </b-row>

    <!-- modal for dosepot -->
    <b-modal
      id="viewDosespotFromMessage"
      centered
      hide-footer
      size="xl"
      v-model="isDosespotModalOpen"
    >
      <template v-slot:modal-header="{ close }">
        <b-container fluid></b-container>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="link" @click="close">
          <b-icon
            icon="x-circle-fill"
            variant="secondary"
            style="width: 30px; height: 30px"
          ></b-icon>
        </b-button>
      </template>
      <b-row no-gutters>
        <b-col cols="12">
          <template v-if="isFetchingDosespot && !this.dosespotURL">
            <b-row align-v="center" style="height: 350px">
              <b-col cols="12" class="text-center">
                <h1 class="text-primary">Loading Dosespot...</h1>
              </b-col>
            </b-row>
          </template>
          <iframe
            v-else
            :src="this.dosespotURL"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="yes"
            allowtransparency="true"
            :style="{ minHeight: iframeHeight + 'px' }"
          ></iframe>
        </b-col>
      </b-row>
    </b-modal>

    <modal-set-reminder
      :patient="{ ...conversationpatient, id: conversationpatient.userId }"
    ></modal-set-reminder>

    <modal-forward-messages
      :selected-messages="selectedMessages"
      :conversationmemberlist="conversationmemberlist"
      :conversationid="conversationId"
      @success="onForwardMessageSuccess"
    ></modal-forward-messages>
    <modal-view-forward-messages
      ref="viewForwardMessages"
      :conversationmemberlist="conversationmemberlist"
      :conversationid="conversationId"
    ></modal-view-forward-messages>

    <portal to="notes">
      <ModalBodyNotes
        :show-notes-modal.sync="showNotesModal"
        :patient-id="conversationpatient.userId"
      />
    </portal>

    <SideModal
      :key="cannedReplyModalKey"
      :show="showCannedReplyModal"
      @exit="showCannedReplyModal = false"
      header-class="canned-reply-header"
      body-class="canned-reply-body"
      title="Canned Replies"
      side="left"
    >
      <template #body>
        <CannedReplyModal
          @close-canned="showCannedReplyModal = false"
          :patient-name="conversationpatient.firstName"
          :is-visible="showCannedReplyModal"
          @add-attachment="addAttachment"
          @add-message="addMessage"
        />
      </template>
    </SideModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
  differenceInMinutes,
} from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { ChatService } from "@/services/chat.service.js";
import { PrescriptionService } from "@/services/prescription.service.js";
import audio from "@/assets/notifications/pristine.mp3";
const notificationsound = new Audio(audio);
import _ from "lodash";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import SideModal from "@/components/partials/SideModal";
import CannedReplyModal from "@/components/partials/CannedReplyModal";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import ModalForwardMessages from "@/components/messages/ModalForwardMessages.vue";
import ModalViewForwardMessages from "@/components/messages/ModalViewForwardMessages.vue";
import ChatRoutedTooltip from "@/components/partials/ChatRoutedTooltip";

import heightObserver from "@/assets/js/observeHeight.js";
import { OrientationMixin } from "@/mixins/orientation";

// Icons
import IconPaperclip from "@/components/icon/IconPaperclip.vue";
import IconCannedReply from "@/components/icon/IconCannedReply.vue";

export default {
  mixins: [heightObserver, OrientationMixin],
  props: {
    userid: {
      type: String,
      default: localStorage.getItem("userid"),
    },
    conversationlist: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ModalSetReminder,
    SideModal,
    CannedReplyModal,
    ModalBodyNotes,
    ModalForwardMessages,
    ModalViewForwardMessages,
    ChatRoutedTooltip,

    IconPaperclip,
    IconCannedReply,
  },
  data() {
    return {
      // adjustTextArea: false,
      // mobile view save image of patient when selected
      patientImg: undefined,
      patientName: undefined,

      showInformation: false,
      isSending: false,
      preUpload: false,
      newMessage: "",
      filter: null,
      attachtments: [],
      files: [],
      loading: false,
      nextPage: 1,
      messages: [],
      conversationmemberlist: [],
      conversation: {},
      conversationpatient: {
        id: "",
        firstName: "",
      },
      conversationId: "",
      dosespotURL: "",
      showCannedReplyModal: false,
      isFetchingDosespot: false,

      iframeHeight: undefined,

      showNotesModal: false,

      isMarkMessagesEnabled: false,
      selectedMessages: [],
      selectedMessagesId: [],
      messageSelector: [],

      forwardRequestCounter: 0,

      isAdminView: false,
      patientConversationId: "",
      adminConversationId: "",
      hasConversationChanged: false,

      isNeedsReply: false,
      isDosespotModalOpen: false,

      messageReceivedFn: null,
      messageDeliveryConfirmedFn: null,
      prescriptionsWithPrices: [],
      mergeTags: [],

      cannedReplyModalKey: uuidv4(),
    };
  },
  methods: {
    scrollToBottom() {
      const el = this.$refs.bottomOfPage;
      if (el) {
        el.scrollIntoView();
      }
    },
    addAttachment(attachment) {
      this.attachtments.push(attachment);
      this.files.push(attachment);
    },
    addMessage(content) {
      let tags = content.match(/\{(.*?)\}/g);
      if (!tags) {
        tags = [];
      }

      // More static properties
      // Preload into array
      let splitDOB = this.conversationpatient.id
        ? this.conversationpatient.birthday.split("-")
        : this.patientInfo.birthday.split("-");
      let dob = `${splitDOB[1]}/${splitDOB[2]}/${splitDOB[0]}`;

      let tagsToReplace = [
        { tag: "{doctor}", value: this.$store.state.user.currentUser.lastname },
        {
          tag: "{patient}",
          value:
            this.conversationpatient.firstName || this.patientInfo.firstname,
        },
        { tag: "{DOB}", value: dob },
        {
          tag: "{First Name}",
          value:
            this.conversationpatient.firstName || this.patientInfo.firstname,
        },
        {
          tag: "{Last Name}",
          value: this.conversationpatient.lastName || this.patientInfo.lastname,
        },
        {
          tag: "{Name}",
          value: `${
            this.conversationpatient.firstName || this.patientInfo.firstname
          } ${this.conversationpatient.lastName || this.patientInfo.lastname}`,
        },
        {
          tag: "{State}",
          value: this.conversationpatient.state || this.patientInfo.state,
        },
      ];
      for (let tag of tags) {
        let splitted = tag
          .replace(/Price - /g, "")
          .replace(/[{}]/g, "")
          .split("/");

        // If tag is not name/interval/quantity, assume static and continue loop
        if (splitted.length == 1) {
          continue;
        }

        if (splitted[1] == "onetime") {
          splitted[1] = 0;
        }
        // Find prescription
        let prescription = this.prescriptionsWithPrices.filter(
          (o) => o.name == splitted[0]
        )[0];

        if (!prescription) {
          this.$bvToast.toast(
            "Cannot find prescription that matches the tag. Tag will not be translated",
            {
              variant: "warning",
              noCloseButton: true,
              autoHideDelay: 2000,
              toaster: "b-toaster-bottom-center",
            }
          );
          continue;
        }

        // Generate list of all details to search from
        let listOfDetails = [];
        for (let detail of prescription.details) {
          listOfDetails.push(...detail.detail);
        }

        // Search for correct pricing
        let price = listOfDetails.find(
          (o) =>
            Number(o.interval) == Number(splitted[1]) &&
            Number(o.quantity) == Number(splitted[2])
        );

        if (!price) {
          this.$bvToast.toast(
            "Cannot find price that matches the tag. Tag will not be translated",
            {
              variant: "warning",
              noCloseButton: true,
              autoHideDelay: 2000,
              toaster: "b-toaster-bottom-center",
            }
          );
          continue;
        }
        tagsToReplace.push({ tag: tag, value: `$${price.price}` });
      }

      for (let tag of tagsToReplace) {
        // Add escaping of parentheses for progesterone
        let tagEscaped = tag.tag.replace("(", "\\(").replace(")", "\\)");
        let regex = new RegExp(tagEscaped, "g");
        content = content.replace(regex, `${tag.value}`);
      }

      // If message is empty
      if (!this.newMessage) {
        this.newMessage = content;
        this.newMessage = this.newMessage.concat("\n");
      } else {
        // Get cursor position
        const cursor = this.$refs.messageArea.selectionStart;
        let cursorPos = cursor;
        if (cursor || (cursor && this.newMessage.length)) {
          this.newMessage = [
            this.newMessage.slice(0, cursor),
            content.concat("\n"),
            this.newMessage.slice(cursor),
          ].join("");

          setTimeout(() => {
            this.$refs.messageArea.focus();
            cursorPos += content.length + 1;
            this.$refs.messageArea.selectionStart =
              this.$refs.messageArea.selectionEnd = cursorPos;
          }, 10);
        } else {
          this.newMessage = this.newMessage.concat(content).concat("\n");
        }
      }
      this.$bvToast.show("cannedReplyToast");
    },
    openUpload() {
      this.$refs.fileInput.click();
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.fileInput.files;
      this.preUpload = true;
      const maxFileSize = 30 * 1024 * 1024; //30mb
      for (var i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i] && uploadedFiles[i].size > maxFileSize) {
          this.$bvToast.toast("Maximum file size is 7mb", {
            variant: "warning",
            noCloseButton: true,
            autoHideDelay: 2000,
            toaster: "b-toaster-bottom-center",
          });
        } else {
          this.files.push(uploadedFiles[i]);
          uploadedFiles[i].url = URL.createObjectURL(uploadedFiles[i]);
          this.attachtments.push(uploadedFiles[i]);
        }
      }
    },
    removeFile(key) {
      this.attachtments.splice(key, 1);
      if (this.attachtments.length == 0) {
        this.attachtments = [];
        this.files = [];
        this.preUpload = false;
      }
    },
    async sendMessage(needsreply) {
      try {
        if (this.isMessageBodyEmpty) {
          if (!(this.files.length > 0)) {
            return;
          }
        }
        if (this.isSending === false) {
          this.isSending = true;

          let obj = {
            id: uuidv4(),
            conversationId: this.conversationId,
            sentAt: Math.floor(Date.now() / 1000),
            message: this.newMessage,
            needsReply: needsreply ? true : false,
          };
          if (this.files.length > 0) {
            obj.attachment = Object.keys(this.files).map((key) => ({
              name: this.files[key].name,
              size: this.files[key].size,
              type: this.files[key].type,
              file: this.files[key],
            }));
            this.socket.emit("new_message", obj);
            this.attachtments = [];
            this.files = [];
          } else {
            this.socket.emit("new_message", obj);
          }

          this.newMessage = "";
          this.preUpload = false;

          var chatBox = document.querySelector(".card-chat-body");
          chatBox.scrollTop = 9999;

          this.$refs.conversationListRef?.scrollTo({
            top: 0,
            behavior: "smooth",
          });

          this.$refs.mobileConversationListRef?.scrollTo({
            top: 0,
            behavior: "smooth",
          });

          this.$refs.bottomOfPage.scrollIntoView();

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error.message);
        return false;
      } finally {
        this.isSending = false;
        this.newMessage = "";
        this.attachtments = [];
        this.files = [];
        this.preUpload = false;
      }
    },
    async sendMessageNeedsReply() {
      await this.sendMessage(true);
      await this.$store.dispatch(
        "message/needPatientReply",
        this.conversationId
      );
      this.getConversationMemberList(
        this.conversationId,
        this.conversation.userid
      );
    },
    async setConversationId(conversation) {
      this.conversationpatient = {
        id: "",
        firstName: "",
      };
      this.isNeedsReply = false;
      this.hasConversationChanged = true;
      this.isAdminView = false;
      this.patientConversationId = conversation.id;
      this.adminConversationId = "";
      this.conversationId = conversation.id;
      this.patientImg = conversation.profileUrl;
      this.patientName = conversation.name;
      this.isMarkMessagesEnabled = false;
      this.selectedMessages = [];
      this.messageSelector = [];
      this.forwardRequestCounter = 0;
      this.listMessage(this.conversationId);
    },
    getConversationMemberList(conversationid, userid) {
      ChatService.listConversationMember(conversationid).then(
        ({ data: members }) => {
          this.conversationmemberlist = members;
          for (let member of members) {
            if (member.type === "PATIENT") {
              localStorage.setItem("patientId", userid);
              this.conversationpatient = member;
            }
          }
          this.updateConversationNeedsReply();
        }
      );
    },
    updateConversationNeedsReply() {
      const foundIndex = this.conversationlist.findIndex(
        (x) => x.id == this.conversationId
      );
      if (foundIndex > -1) {
        if (this.conversationlist?.[foundIndex]) {
          Vue.set(
            this.conversationlist[foundIndex],
            "needspatientreply",
            this.conversationpatient.needsPatientReply
          );
          Vue.set(
            this.conversationpatient,
            "needsPatientReply",
            this.conversationpatient.needsPatientReply
          );
        }
      }
    },
    async getDynamicPricing(patientid) {
      const { data } = await PrescriptionService.getProductDosageAndUnit(
        patientid
      );
      this.prescriptionsWithPrices = data;
    },
    async listMessage(conversationid) {
      try {
        if (this.isAdminView) {
          if (this.adminConversationId) {
            this.conversationId = this.adminConversationId;
          } else {
            const patientId =
              this.$route.query.patientid || this.conversationpatient.userId;
            const { data } = await ChatService.getAdminConversationDetails(
              patientId
            );
            this.adminConversationId = data.id;
            this.conversationId = data.id;
          }
        } else {
          this.conversationId = conversationid;
          this.conversation = await this.conversationlist.find(
            (x) => x.id === conversationid
          );
          await this.getDynamicPricing(this.conversation.patientId);
        }

        this.newMessage = "";
        this.nextPage = null;
        this.loading = true;
        this.loadingMessage = false;
        this.hasConversationChanged = false;

        ChatService.listMessage(this.conversationId, this.nextPage).then(
          ({ data: messages }) => {
            this.messages = messages.items;
            this.nextPage = messages.nextpage;
            this.loading = false;
          }
        );

        this.getConversationMemberList(
          this.conversationId,
          this.conversation.userid
        );
      } catch (error) {
        console.log(error.message);
        this.loading = false;
      }
    },
    fetchDosespotURL(userid) {
      this.isFetchingDosespot = true;
      this.$store
        .dispatch("prescription/generateDospatURL", userid)
        .then((dosespotURL) => {
          this.dosespotURL = dosespotURL;
        })
        .finally(() => (this.isFetchingDosespot = false));
    },
    getMemberProfileUrl(userid) {
      let member = this.conversationmemberlist.find((x) => x.userId === userid);
      if (member) {
        return member.profileUrl || require("@/assets/images/anon.jpeg");
      } else {
        return "";
      }
    },
    async chatMessage() {
      const { data: messages } = await ChatService.listMessage(
        this.conversationId,
        this.nextPage
      );
      for (let i = 0; i < messages.items.length; i++) {
        let message = messages.items[i];
        this.messages.push(message);
      }
      if (messages.nextpage) {
        this.nextPage = messages.nextpage;
      } else {
        this.nextPage = null;
      }
    },
    messageScroll(e) {
      // let scroll = e.target.scrollTop + e.target.clientHeight === e.target.offsetHeight <-- OLD VERSION (THIS IS WRONG ILONG)
      let scroll =
        e.target.scrollTop + e.target.scrollHeight === e.target.clientHeight;
      let scrollwithallowance1 =
        e.target.scrollTop + e.target.scrollHeight < e.target.clientHeight + 10;
      try {
        if (scroll || scrollwithallowance1) {
          if (this.nextPage && !this.loadingMessage) {
            this.loadingMessage = true;
            this.chatMessage();
            this.loadingMessage = false;
          }
        } else {
          // console.log(e.target.scrollTop + e.target.scrollHeight)
          // console.log(e.target.clientHeight)
        }
      } catch {
      } finally {
        this.loadingMessage = false;
      }
    },
    parseBirthdate(date) {
      return format(new Date(date), "MMMM d, yyyy");
    },
    matchModalHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    async onNeedPatientReply() {
      await this.$store.dispatch(
        "message/needPatientReply",
        this.conversationId
      );
      this.getConversationMemberList(
        this.conversationId,
        this.conversation.userid
      );
    },
    async onRemoveNeedPatientReply() {
      await this.$store.dispatch(
        "message/removeNeedPatientReply",
        this.conversationId
      );
      this.getConversationMemberList(
        this.conversationId,
        this.conversation.userid
      );
    },
    nextConversationList() {
      this.$store.dispatch("message/nextConversationListPage");

      if (this.filter) {
        this.$store.dispatch("message/searchConversationList", this.filter);
      } else {
        this.$store.dispatch("message/getConversationList");
      }
    },
    previousConversationList() {
      this.$store.dispatch("message/previousConversationListPage");

      if (this.filter) {
        this.$store.dispatch("message/searchConversationList", this.filter);
      } else {
        this.$store.dispatch("message/getConversationList");
      }
    },
    onConversationSearch: _.debounce(function () {
      this.$store.dispatch("message/setConversationListPage", 1);
      this.$store.dispatch("message/searchConversationList", this.filter);
    }, 350),
    onLoadAllConversations() {
      this.$router.push("/message");
      this.$emit("fetch-conversations");
    },
    onForwardCheckboxChange(e, message) {
      if (!e.target.checked) {
        e.target.checked = true;

        if (
          message.id === this.messageSelector?.[0] &&
          this.messageSelector.length === 1
        ) {
          this.selectedMessages = [];
          this.messageSelector = [];
          return;
        }

        if (
          message.id === this.messageSelector?.[0] &&
          this.messageSelector.length === 2
        ) {
          this.selectedMessages = [message];
          this.messageSelector.splice(1, 1);
          return;
        }

        const removeIndex = this.selectedMessages.findIndex(
          (msg) => msg.id === message.id
        );
        this.selectedMessages.splice(
          this.messageSelector.includes(message.id)
            ? removeIndex
            : removeIndex + 1,
          this.selectedMessages.length
        );

        if (this.selectedMessages.length > 1) {
          this.messageSelector[1] =
            this.selectedMessages[this.selectedMessages.length - 1].id;
        } else {
          this.messageSelector.splice(1, 1);
        }

        return;
      }

      this.selectedMessages = [];
      if (this.messageSelector.length <= 1) {
        if (message.id !== this.messageSelector?.[0]) {
          this.messageSelector.push(message.id);
        }
      } else if (this.messageSelector.length === 2) {
        this.messageSelector[1] = message.id;
      }

      if (this.messageSelector.length === 1) {
        this.selectedMessages.push(message);
      } else if (this.messageSelector.length === 2) {
        let count = 0;
        this.selectedMessages = [];

        const firstMessageIndex = this.messages.findIndex(
          (el) => el.id === this.messageSelector[0]
        );
        const secondMessageIndex = this.messages.findIndex(
          (el) => el.id === this.messageSelector[1]
        );

        let clonedMessages;
        if (firstMessageIndex > secondMessageIndex) {
          clonedMessages = [...this.messages].reverse();
        } else if (firstMessageIndex < secondMessageIndex) {
          clonedMessages = [...this.messages];
        }

        for (const currentMessage of clonedMessages) {
          if (count === 2) {
            break;
          }
          if (this.messageSelector.includes(currentMessage.id)) {
            count += 1;
          }

          if (count === 1 || count === 2) {
            this.selectedMessages.push(currentMessage);
          }
        }
      }
    },
    onCancelMarkMessages() {
      this.isMarkMessagesEnabled = false;
      this.selectedMessages = [];
      this.messageSelector = [];
      this.forwardRequestCounter = 0;
    },
    onForwardMessageSuccess() {
      this.isMarkMessagesEnabled = false;
      this.selectedMessages = [];
      this.messageSelector = [];
      this.forwardRequestCounter = 0;
    },
    onForwardRequest() {
      if (this.currentDoctor.id) {
        return;
      }

      if (this.forwardRequestCounter === 0) {
        this.forwardRequestCounter += 1;
        this.isMarkMessagesEnabled = true;
      } else {
        if (this.selectedMessages.length > 0) {
          this.$bvModal.show("forwardMessage");
        } else {
          this.$bvToast.toast("Kindly mark your messages first.", {
            title: "Something went wrong",
            variant: "warning",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
        }
      }
    },
    isChecked(message) {
      return this.selectedMessages.some((msg) => msg.id === message.id);
    },
    getAdminName(userid) {
      const selectedAdmin = this.conversationmemberlist.find(
        (member) => member.userId === userid
      );
      return selectedAdmin
        ? `${selectedAdmin.firstName} ${selectedAdmin.lastName} (Admin)`
        : "";
    },
    connectToSocket() {
      if (this.socket) {
        this.messageDeliveryConfirmedFn = (message) => {
          if (message.conversationid === this.conversationId) {
            console.log("Confirmed: ", message);
            this.onMessageReceived(message);
          }
          this.onConversationUpdate(message);
        };
        this.socket.on(
          "message_delivery_confirmed",
          this.messageDeliveryConfirmedFn
        );

        this.messageReceivedFn = (message) => {
          if (message.conversationid === this.conversationId) {
            console.log("Received message", message);
            this.onMessageReceived(message);
          }
          this.onConversationUpdate(message);
        };
        this.socket.on("message", this.messageReceivedFn);

        this.socket.on(
          "conversation:member_active_status_changed",
          async (message) => {
            if (message.conversationId === this.conversationId) {
              this.conversationId = "";
              this.conversationpatient = {
                id: "",
                firstName: "",
              };
              this.conversation = {};
            }

            var foundIndex = this.conversationlist.findIndex(
              (x) => x.id == message.conversationId
            );
            if (foundIndex > -1) {
              this.conversationlist.splice(foundIndex, 1);
            } else {
              const { data: conversationData } =
                await ChatService.getConversationInfo(message.conversationId);
              this.conversationlist.splice(0, 0, conversationData);
              this.conversationlist.sort((a, b) => {
                return Number(b.lastactivity) - Number(a.lastactivity);
              });
            }
          }
        );
      }
    },
    onConversationUpdate(message) {
      this.loading = false;
      this.conversationlist.find((element) => {
        if (element.id == message.conversationid) {
          element.lastmessage = message.message;
        }
      });
      var chatBox = document.querySelector(".card-chat-body");
      setTimeout(() => {
        chatBox?.scrollTo({ top: 9999, behavior: "smooth" });
        this.$refs.conversationListRef?.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        this.$refs.mobileConversationListRef?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 300);

      // needs reply
      var foundIndex = this.conversationlist.findIndex(
        (x) => x.id == message.conversationid
      );
      if (foundIndex > -1) {
        this.conversationlist[foundIndex]["lastactivity"] = message?.sentat;
        this.conversationlist[foundIndex]["lastmessage"] = message?.message;
        if (this.conversationlist?.[foundIndex]) {
          if (
            (this.userid === message.userid &&
              Object.hasOwn(message, "needsreply")) ||
            this.userid !== message.userid
          ) {
            Vue.set(
              this.conversationlist[foundIndex],
              "needspatientreply",
              message?.needsreply
            );
            Vue.set(
              this.conversationpatient,
              "needsPatientReply",
              message?.needsreply
            );
          }

          const removedItem = this.conversationlist.splice(foundIndex, 1);
          this.conversationlist.splice(0, 0, removedItem[0]);
        }
      }
    },
    onMessageReceived(message) {
      if (message.action === "SENT") {
        var newmessage = message;
        if (newmessage.userid !== this.userid) {
          notificationsound.play();
          this.$store.dispatch("message/markAsRead", {
            conversationid: newmessage.conversationid,
            messageid: newmessage.id,
          });
        }
        if (newmessage.type === "prescription_confirmation") {
          if (newmessage.custommessage.constructor.name === "String") {
            newmessage.custommessage = JSON.parse(newmessage.custommessage);
          }
        }
        this.messages.splice(0, 0, newmessage);
        return this.messages;
      } else if (message.action === "UPDATED") {
        var messageupdate = message;
        let messagetoupdate = this.messages.find(
          (x) => x.id === messageupdate.id
        );
        if (messagetoupdate != undefined) {
          if (messagetoupdate.type === "prescription_confirmation") {
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          } else if (messagetoupdate.type === "prescription_followup") {
            messagetoupdate.custommessage = JSON.parse(
              messageupdate.custommessage
            );
            messagetoupdate.actionstatus = messageupdate.actionstatus;
            notificationsound.play();
          }
        }
      }
    },
  },
  computed: {
    patientLastReplyDate() {
      const findPatientLastReply = this.messages.find(
        (message) => message.userid === this.conversationpatient.userId
      );
      if (findPatientLastReply) {
        const lastPatientReplyDate = fromUnixTime(findPatientLastReply.sentat);
        const minuteTimeDifference = differenceInMinutes(
          new Date(),
          lastPatientReplyDate
        );
        if (minuteTimeDifference >= 3) {
          return formatDistanceToNowStrict(lastPatientReplyDate);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    isMessageBodyEmpty() {
      if (this.newMessage) {
        return this.newMessage.trim() === "";
      } else {
        // Check if there's a file included during sending
        return !(this.files.length > 0);
      }
    },
    ...mapGetters("socket", {
      socket: "getSocket",
    }),
  },
  watch: {
    conversationlist: {
      deep: true,
      handler(val) {
        let patientId =
          this.$route.query.patientid || localStorage.getItem("patientId");
        val.find((x) => {
          if (x.userid === patientId) {
            this.conversationId = x.id;
          }
        });
      },
    },
    // conversationId: {
    //   handler(id) {
    //     this.listMessage(id);
    //   },
    // },
    isAdminView(val) {
      if (!this.hasConversationChanged) {
        if (val) {
          this.listMessage();
        } else {
          this.listMessage(this.patientConversationId);
        }
      }
    },
    isDosespotModalOpen(val) {
      if (val) {
        this.fetchDosespotURL(this.conversationpatient.userId);
      }
    },
    async showCannedReplyModal(val) {
      if (!val) {
        // Delay to prevent immediate closing of the side modal
        setTimeout(() => {
          this.cannedReplyModalKey = uuidv4();
        }, 500);
      }
    },
  },
  mounted() {
    this.userid = localStorage.getItem("userid");
    this.connectToSocket();
    this.matchModalHeight();

    window.addEventListener("resize", () => {
      this.matchModalHeight();
    });
  },
  destroyed() {
    if (this.messageReceivedFn) {
      this.socket.off("message", this.messageReceivedFn);
      this.socket.off(
        "message_delivery_confirmed",
        this.messageDeliveryConfirmedFn
      );
    }
  },
};
</script>

<style scoped>
::v-deep #viewDosespotFromMessage .modal-dialog {
  min-width: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

::v-deep #viewDosespotFromMessage .modal-body {
  padding: 0;
}

::v-deep #viewDosespotFromMessage .modal-header {
  border: none;
}

::v-deep #forward-dropdown .dropdown-menu .dropdown-item {
  color: #1a6a72;
  padding: 0.875rem;
}

::v-deep #forward-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #ffecca;
}

::v-deep #forward-dropdown .dropdown-menu {
  border-top: 3px solid #1a6a72;
  padding: 0;
}

.forward-checkbox.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #ff7a37 !important;
  background-color: #ff7a37 !important;
}
</style>

<style lang="scss" scoped>
.tw-chat-box,
.tw-chat-list-header,
.tw-patient-information {
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;
  background-color: #f9fbfd;
}

.patient-selected {
  background-color: $quaternary;
}

.chat-patient-list {
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;
  background-color: #f9fbfd;
  border-right: 0;
}

.followup-update {
  background-color: #e9f5f3;
}

.disabled {
  pointer-events: none; //This makes it not clickable
  opacity: 0.6; //This grays it out to look disabled
}

.list-search {
  height: 50px;
}
.close-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 2;
  background-color: $white;
  border-radius: 100%;
}

.pre-upload {
  border-top: 1px solid rgba($primary, 0.015);
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  // background-color: $white;
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;

  .img-holder {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  + .card-footer {
    border-top: 0;
  }
}

.content {
  border-top: 1px solid rgba($primary, 0.05);
  height: calc(100vh - 200px);
  overflow: hidden;

  > div {
    background-color: $white;
    transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;
    padding: 0;

    .card {
      border: 0;
      margin-bottom: 0;
    }

    &:first-child {
      background-color: rgba($primary, 0.015);
    }

    &:nth-child(3),
    &:last-child {
      background-color: $quaternary;
    }
  }
}

.darkmode .content {
  > div {
    &:nth-child(3),
    &:last-child {
      background-color: lighten(#000, 10%);
    }
  }
}

.list-unstyled {
  height: calc(100vh - 260px);
  margin-bottom: 0;
  overflow: auto;
}

.chat-input {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding-bottom: var(--safe-area-inset-bottom);
}

.patient-info {
  background-color: transparent;
  .card-header {
    border-bottom: 1px solid rgba($primary, 0.015);
  }
  .card-footer {
    border-color: rgba($primary, 0.015);
  }
  .card-body {
    height: calc(100vh - 500px);
    overflow: auto;
    .list-group-item {
      border-bottom: 1px solid rgba($primary, 0.1);
    }
  }
}
.patient-list {
  transition: background-color ease-in-out 0.5s, border-color ease-in-out 0.5s;
  display: flex;
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  &.selected {
    background-color: $quaternary;
  }
  .list-img {
    width: 50px;
    display: inline-block;
  }
  .list-content {
    flex: 1;
    min-width: 0; // fix text-truncate
    h3 {
      color: #19191c;
    }
    p {
      color: #333333;
    }
  }
}
.chats {
  .card-body {
    display: flex;
    padding: 0;
    overflow: auto;
    align-items: flex-start;
    flex-flow: column-reverse;
    transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .card-footer {
    padding: 0.5rem;
  }
}

.chat-box {
  display: flex;
  list-style: none;
  // padding: 0 1.5rem;
  margin: 0;
  width: 100%;

  .chat-inner {
    display: flex;
    flex-flow: column-reverse;
    .time-stamp {
      font-size: 9px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0em;
      // &.patient {
      //   position: absolute;
      //   bottom: -15px;
      //   right: 0;
      //   width: 90px;
      // }
      // &.doctor {
      //   position: absolute;
      //   bottom: -15px;
      //   left: 0;
      //   width: 90px;
      // }
    }
    .time-stamp-followup {
      font-size: 12px;
      font-weight: 400;
      line-height: 11px;
      letter-spacing: 0em;
    }
  }
  > div,
  li {
    width: 100%;
  }
  .chat-dated {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }
  .chat-bubble {
    display: flex;
    padding: 1rem 0;
    &.doctor {
      flex-direction: row-reverse;
      small {
        margin-right: 10px;
        margin-left: 0;
        text-align: right;
      }
    }
    .chat-img {
      flex: 30px 0 0;
      width: 30px;
      height: 30px;
      align-self: flex-end;
      border-radius: 30px;
      overflow: hidden;
      background-color: $tertiary;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .chat {
      flex: 0 auto;
      max-width: 60%;
      padding: 0.75rem 1rem;
      margin-right: 10px;
      background-color: rgba(26, 106, 114, 0.025);
      white-space: pre-line;
      word-break: break-word;
      position: relative;
      &.patient {
        margin-left: 10px;
        margin-right: 0;
        background-color: $quaternary;
      }
      &.datetime {
        margin-left: 10px;
        margin-right: 0;
        // background-color: $quaternary;
      }
    }
    .admin {
      background-color: #edffe1 !important;
    }
    small {
      align-self: flex-end;
      // margin-left: 10px;
    }
  }
}

.darkmode .chat-box .chat-bubble .chat.admin {
  background-color: #143b30 !important;
}

@media (max-width: 992px) {
  .patient-info {
    .card-body {
      height: auto;
    }
  }
  .content {
    border: 0;
    > div {
      margin-bottom: 30px;
      &:first-child {
        border: 1px solid rgba($primary, 0.05);
      }
      &:last-child {
        background-color: $white;
      }
    }
  }
  .list-unstyled {
    height: calc(100vh - 272px);
  }
}

#cannedReplyToast__toast_outer {
  z-index: 999 !important;
  margin-top: 40vh;
  margin-left: 5vw;
}

.chat-mobile {
  position: absolute;
  background-color: $white;

  + .chat-mobile {
    z-index: 2;
    height: 100%;
    margin-bottom: 20px;
    .card {
      background-color: $quaternary;
    }
  }
  .card {
    border: 1px solid rgba($primary, 0.05) !important;
  }
}
.text-muted {
  color: #4f4f4f !important;
}

.btn-patient-reply {
  border-bottom: 1px solid #ff7a37;
  padding-bottom: 0.125rem;
}

.patient-reply-description {
  font-size: 14px;
  text-transform: none;
  color: #4f4f4f;
  letter-spacing: normal;
}

.selected-message {
  background: rgba(255, 236, 202, 0.33);
}
</style>
